import service from "@/api/service";
import { SearchRequest } from "@/api/menu-category-shop/request";
import { GetResponse, SearchResponse } from "@/api/menu-category-shop/response";

/**
 * メニューカテゴリ店舗一覧・検索APIをコールします。
 *
 * @param searchRequest メニューカテゴリ店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post(
    "/search-menu-category-shop",
    searchRequest
  );
  return response.data as SearchResponse;
}

/**
 * メニューカテゴリ店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-menu-category-shop");
  return response.data as GetResponse;
}
